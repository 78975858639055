import { mapActions, mapGetters } from 'vuex'
import packageColors from '../../helpers/package-colors.js'
import ReportExport from '../../features/report-export/index.vue'
import reportMixin from '@/mixins/reportMixin'

export default {
  components: {
    ReportExport
  },
  mixins: [reportMixin],
  computed: {
    headers () {
      return [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Package',
          value: 'package'
        },
        {
          text: 'Current progress',
          value: 'material'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    },
    colors () {
      return packageColors.colors
    },
    ...mapGetters({
      lessonData: 'statistics/lessonData'
    }),
    reportFn () {
      return this.createReportFn({
        url: `${process.env.VUE_APP_ROOT_STATISTIC}courses/${this.$route.params.courseId}/modules/${this.$route.params.moduleId}/lessons/${this.$route.params.materialId}`,
        method: 'get'
      })
    }
  },
  created () {
    this.fetchLessonDetails({
      courseId: this.$route.params.courseId,
      moduleId: this.$route.params.moduleId,
      materialId: this.$route.params.materialId
    })
  },
  methods: {
    ...mapActions({
      fetchLessonDetails: 'statistics/GET_STATISTICS_LESSON_DETAILS'
    })
  }
}
